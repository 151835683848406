import { nanoid } from 'nanoid';

export async function getLocalPhoto(url: string) {
  try {
    const photo = await (await fetch(url)).blob();
    return new File([photo], nanoid() + '.jpg', { type: photo.type });
  } catch {
    return '';
  }
}
