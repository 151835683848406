/* eslint-disable react/prop-types */
import React from 'react';
import dbIcon from '@assets/images/database.svg';
import { Typography } from '@mui/material';
import { titleSX } from './NoResultTitle.styles';

export const NoResultTitleMemorized = ({ titleText = 'Нет данных', show = true }) =>
  show ? (
    <Typography variant="h4" sx={titleSX} gutterBottom={false}>
      <img src={dbIcon} alt="no result" />
      {titleText}
    </Typography>
  ) : null;

export const NoResultTitle = React.memo(NoResultTitleMemorized);
