type ObjectData = { [key: string]: unknown };

export function skipObjectKeys(object: ObjectData, skipKeys: string[]) {
  const newObject: ObjectData = {};

  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      if (!skipKeys.includes(key)) {
        newObject[key] = object[key];
      }
    }
  }

  return { ...newObject };
}
