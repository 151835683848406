import React from 'react';
import * as I from '@models';
import { memo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { routes } from '@utils';
import { Wrapper } from 'components/layout';
import { commonApi } from '@store';
import { WindowLoader } from 'components/feedback';
const { useGetUserQuery } = commonApi;

const PrivateRouteMemorized = (props: I.PrivateRouteProps) => {
  const { data: user, isFetching } = useGetUserQuery();
  const { onlyFor } = props;

  if (isFetching) {
    return <WindowLoader backdropOpacity={1} />;
  }

  if (user && onlyFor.includes(user.role)) {
    return (
      <Wrapper>
        <Outlet />
      </Wrapper>
    );
  }

  return <Navigate to={routes.login} />;
};

export const PrivateRoute = memo(PrivateRouteMemorized);
