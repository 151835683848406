import React from 'react';
import WarningIcon from '@mui/icons-material/WarningAmber';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import CheckedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { IconVariant, SnackbarOrigin, SnackbarProvider } from 'notistack';
import { rootStyles, iconStyles } from './SnackbarCustomization.styles';
import { SnackbarCloseButton } from './SnackbarCloseButton';

const icons: Partial<IconVariant> = {
  success: <CheckedIcon color="success" style={iconStyles} />,
  error: <WarningIcon color="error" style={iconStyles} />,
  info: <InfoIcon color="info" style={iconStyles} />,
  warning: <InfoIcon color="warning" style={iconStyles} />,
};

const origin: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

export function SnackbarCustomization({ children }: { children: React.ReactNode }) {
  // RENDER
  return (
    <SnackbarProvider
      iconVariant={icons}
      anchorOrigin={origin}
      style={rootStyles}
      autoHideDuration={3000}
      action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
    >
      {children}
    </SnackbarProvider>
  );
}
