import React from 'react';
import classes from './DotLoaer.module.css';

function DotLoaderMemorized() {
  return (
    <div className={classes['lds-ellipsis']}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

export const DotLoader = React.memo(DotLoaderMemorized);
