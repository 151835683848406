import { SxProps, Theme } from '@mui/material';

export const rootSX: SxProps<Theme> = {
  position: 'relative',
};

export const photosCardSX = {
  position: 'relative',
  width: 180,
  height: 180,
  overflow: 'hidden',
};

export const actionsPanel: SxProps<Theme> = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  p: 1,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  width: '100%',
};
