import * as I from '@models';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

export function getResponseErrorMessage(error: FetchBaseQueryError | I.ApiErrorResponse | SerializedError | undefined) {
  try {
    if (error) {
      // FetchBaseQueryError
      if ('error' in error) {
        return error.error;
      }

      // ApiErrorResponse
      if ('data' in error && typeof error.data !== 'string') {
        return (error as I.ApiErrorResponse).data.error.message;
      }
    }
    return 'Что-то пошло не так';
  } catch {
    return 'Что-то пошло не так';
  }
}
