import React, { memo, useState } from 'react';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { removeToken, routes } from '@utils';
import { paperPropsSX, avatarSX, menuHeaderBoxSX, mainAvatarSX } from './UserProfile.styles';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { useTypedDispatch } from '@hooks';
import { ChangePassword } from './ChangePassword';
import { commonApi, headerActions } from '@store';
import {
  Avatar,
  Menu,
  MenuItem,
  Tooltip,
  ListItemIcon,
  IconButton,
  Divider,
  Box,
  Typography,
  MenuProps,
} from '@mui/material';
const { passwordDialogSwitcher } = headerActions;
const { useGetUserQuery } = commonApi;

function UserProfileMemorized() {
  const dispatch = useTypedDispatch();
  const { data: user } = useGetUserQuery();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  // exit
  const handleExit = useCallback(() => {
    handleCloseMenu();
    if (window.confirm('Подтвердите действие для выхода')) {
      removeToken();
      document.location.replace(routes.login);
    }
  }, [handleCloseMenu]);

  const handleShowNewPassword = useCallback(() => {
    dispatch(passwordDialogSwitcher(true));
  }, [dispatch]);

  // prettier-ignore
  const menuOptions = useMemo(() => ({
    anchorEl: anchorEl,
    open: open,
    onClose: handleCloseMenu,
    onClick: handleCloseMenu,
    transformOrigin: { horizontal: "right", vertical: "top" },
    anchorOrigin: { horizontal: "right", vertical: "bottom" },
    PaperProps: paperPropsSX,
  } as MenuProps), [anchorEl, handleCloseMenu, open]);

  // RENDER
  return (
    <>
      <Tooltip title="Мой профиль">
        <IconButton onClick={handleClick} size="small">
          <Avatar sx={avatarSX}>
            <AccountCircle color="inherit" fontSize="inherit" />
          </Avatar>
        </IconButton>
      </Tooltip>

      <Menu {...menuOptions}>
        <Box sx={menuHeaderBoxSX}>
          <Avatar sx={mainAvatarSX}>
            {user?.firstName[0]}
            {user?.lastName[0]}
          </Avatar>
          <Typography variant="h6" gutterBottom={false}>
            {user?.name}
          </Typography>
          <Typography variant="caption" gutterBottom={false} color="text.secondary">
            {user?.phoneNumber}
          </Typography>
        </Box>

        <Divider />

        <MenuItem onClick={handleShowNewPassword}>
          <ListItemIcon>
            <LockIcon fontSize="small" />
          </ListItemIcon>
          Изменить пароль
        </MenuItem>

        <MenuItem onClick={handleExit}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Выход
        </MenuItem>
      </Menu>

      <ChangePassword />
    </>
  );
}

export const UserProfile = memo(UserProfileMemorized);
