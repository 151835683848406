import React, { memo } from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { useMemo } from 'react';

function PhoneFielddMemorized(props: TextFieldProps) {
  // prettier-ignore
  const InputPropsMemorized = useMemo(() => ({
    startAdornment: (
      <InputAdornment
        sx={{
          height: 'inherit',
          paddingRight: 1,
          borderTopLeftRadius: (theme) => theme.shape.borderRadius + 'px',
          borderBottomLeftRadius: (theme) => theme.shape.borderRadius + 'px',
          borderRight: (theme) => `1px solid ${theme.palette.divider}`,
        }}
        disableTypography
        position="start"
      >
        +992
      </InputAdornment>
    ),
  }),[]);

  // RENDER
  return <TextField {...props} InputProps={InputPropsMemorized} />;
}

export const PhoneField = memo(PhoneFielddMemorized);
