export function readFile(file: Blob) {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        }
      },
      false,
    );
    reader.readAsDataURL(file);
  });
}
