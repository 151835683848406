import { Theme, SxProps } from '@mui/material';
import { alpha } from '@mui/system';

export const contentSX: SxProps<Theme> = {
  position: 'relative',
  height: 500,
  overflow: 'hidden',
  backgroundColor: alpha('#1F2023', 0.7),
};

export const closeButtonSX = {
  position: 'absolute',
  top: 10,
  right: 10,
  color: 'grey.300',
};

export const photosQuantitySX = {
  py: 1,
  px: 2,
  backgroundColor: 'grey.300',
};
