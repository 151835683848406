export const avatarSX = {
  width: 32,
  height: 32,
};

export const paperPropsSX = {
  elevation: 3,
  sx: {
    minWidth: 200,
    overflow: 'visible',
    mt: 1.5,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

export const menuHeaderBoxSX = {
  pt: 2,
  pl: 3,
  pb: 3,
  pr: 3,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

export const mainAvatarSX = {
  width: 62,
  height: 62,
  mb: 1,
};
