import React, { lazy, Suspense } from 'react';
import { WindowLoader, PrivateRoute } from '@components';
import { Route, Routes } from 'react-router-dom';
import { routes, roles } from '@utils';

const { ALL_ROLES, MAIN, SITE_MANAGER } = roles;

const HomePage = lazy(() => import('@views/Home'));

const AutoAdsPage = lazy(() => import('@views/AutoPage'));
const AutoAdsAddPage = lazy(() => import('@views/Auto/AutoAddAdvert/AutoAddAdverts'));
const AutoAdsEditPage = lazy(() => import('@views/Auto/EditAutoPage/EditAutoPage'));

const AdsPage = lazy(() => import('@views/Ads/AdsPage/AdsPage'));
const AdsAddPage = lazy(() => import('@views/AdsAdd'));
const AdsEditPage = lazy(() => import('@views/AdsEdit'));

const BuildersPage = lazy(() => import('@views/Builders'));
const BuildersAddPage = lazy(() => import('@views/BuildersAdd'));
const BuildersEditPage = lazy(() => import('@views/BuildersEdit'));

const TarifsPage = lazy(() => import('@views/Tarifs'));
const TarifsAddPage = lazy(() => import('@views/TarifsAdd'));
const TarifsEditPage = lazy(() => import('@views/TarifsEdit'));

const ClientsPage = lazy(() => import('@views/Clients'));
const ClientsBalancePage = lazy(() => import('@views/ClientsBalance'));
const ClientsStatisticsPage = lazy(() => import('@views/ClientsStatistics'));

const SMSMailingEditPage = lazy(() => import('@views/SMSMailing'));
const UsersPage = lazy(() => import('@views/Users'));
const LoginPage = lazy(() => import('@views/Login'));
const NotFoundPage = lazy(() => import('@views/NotFound'));
const Feedback = lazy(() => import('@views/Feedbacks/Feedback'));

const TendersPage = lazy(() => import('@views/Tenders'));
const TendersAddPage = lazy(() => import('@views/TendersAdd'));
const TendersEditPage = lazy(() => import('@views/TendersEdit'));

const MessagesPage = lazy(() => import('@views/Messages/Messages'));
const TransactionsPage = lazy(() => import('@views/Transactions/Transactions'));
const CallbackPage = lazy(() => import('@views/CallBack/Callback'));
console.log('testinddddg fffff');
export default function App() {
  // RENDER
  return (
    <Suspense fallback={<WindowLoader />}>
      <Routes>
        {/* HOME */}
        <Route path={routes.home} element={<PrivateRoute onlyFor={ALL_ROLES} />}>
          <Route path={routes.home} element={<HomePage />} />
        </Route>

        {/* Auto ADS */}
        <Route path={routes.auto} element={<PrivateRoute onlyFor={ALL_ROLES} />}>
          <Route path={routes.auto} element={<AutoAdsPage />} />
          <Route path={routes.addAuto} element={<AutoAdsAddPage />} />
          <Route path={`${routes.autoEdit}/:autoId`} element={<AutoAdsEditPage />} />
        </Route>

        {/* ADS */}
        <Route path={routes.ads} element={<PrivateRoute onlyFor={ALL_ROLES} />}>
          <Route path={routes.ads} element={<AdsPage />} />
          <Route path={routes.adsAdd} element={<AdsAddPage />} />
          <Route path={`${routes.adsEdit}/:adsID`} element={<AdsEditPage />} />
        </Route>

        {/* BUILDERS */}
        <Route path={routes.builders} element={<PrivateRoute onlyFor={[SITE_MANAGER, MAIN]} />}>
          <Route path={routes.builders} element={<BuildersPage />} />
          <Route path={routes.buildersAdd} element={<BuildersAddPage />} />
          <Route path={`${routes.buildersEdit}/:builderID`} element={<BuildersEditPage />} />
        </Route>

        {/* SMS MAILING  */}
        <Route path={routes.smsMailing} element={<PrivateRoute onlyFor={ALL_ROLES} />}>
          <Route path={routes.smsMailing} element={<SMSMailingEditPage />} />
        </Route>

        {/* USERS */}
        <Route path={routes.users} element={<PrivateRoute onlyFor={[MAIN]} />}>
          <Route path={routes.users} element={<UsersPage />} />
        </Route>

        {/* TARIFS */}
        <Route path={routes.tarifs} element={<PrivateRoute onlyFor={[MAIN]} />}>
          <Route path={routes.tarifs} element={<TarifsPage />} />
          <Route path={routes.tarifsAdd} element={<TarifsAddPage />} />
          <Route path={`${routes.tarifsEdit}/:tarifID`} element={<TarifsEditPage />} />
        </Route>

        {/* CLIENTS */}
        <Route path={routes.clients} element={<PrivateRoute onlyFor={[MAIN]} />}>
          <Route path={routes.clients} element={<ClientsPage />} />
          <Route path={`${routes.clientsBalance}/:clientID`} element={<ClientsBalancePage />} />
          <Route path={`${routes.clientsStatistics}/:clientID`} element={<ClientsStatisticsPage />} />
        </Route>

        {/* TENDERS */}
        <Route path={routes.tenders} element={<PrivateRoute onlyFor={[MAIN]} />}>
          <Route path={routes.tenders} element={<TendersPage />} />
          <Route path={routes.tendersAdd} element={<TendersAddPage />} />
          <Route path={`${routes.tendersEdit}/:tenderID`} element={<TendersEditPage />} />
        </Route>

        {/* Messages */}
        <Route path={routes.messages} element={<PrivateRoute onlyFor={[MAIN, SITE_MANAGER]} />}>
          <Route path={routes.messages} element={<MessagesPage />} />
        </Route>
        {/* Callback */}
        <Route path={routes.callback} element={<PrivateRoute onlyFor={[MAIN, SITE_MANAGER]} />}>
          <Route path={routes.callback} element={<CallbackPage />} />
        </Route>
        {/* Messages */}
        <Route path={routes.feedbacks} element={<PrivateRoute onlyFor={[MAIN, SITE_MANAGER]} />}>
          <Route path={routes.feedbacks} element={<Feedback />} />
        </Route>
        <Route path={routes.transactions} element={<PrivateRoute onlyFor={[MAIN]} />}>
          <Route path={routes.transactions} element={<TransactionsPage />} />
        </Route>

        {/* LOGIN */}
        <Route path={routes.login} element={<LoginPage />} />

        {/* NOT FOUND */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
}
