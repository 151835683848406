import * as I from '../../models';
import { createApiWrapped } from '../api/api';

export const buildersApi = createApiWrapped.injectEndpoints({
  endpoints: (build) => ({
    getBuilders: build.query<I.BuildersRespons, void>({
      query: () => 'real-estates/developers',
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: 'Builders' as const, id })), 'Builders'] : ['Builders'],
    }),

    getBuilder: build.query<I.Builder, string | number>({
      query: (builderID) => `real-estates/developers/${builderID}`,
      providesTags: (_result, _error, id) => [{ type: 'Builders', id }],
    }),

    addBuilder: build.mutation<void, I.BuilderAdd>({
      query: (body) => ({
        method: 'POST',
        url: 'admin/developers/save',
        body,
      }),
      invalidatesTags: ['Builders'],
    }),

    editBuilder: build.mutation<unknown, I.BuilderEdit>({
      query: (body) => ({
        method: 'POST',
        url: 'admin/developers/save',
        body,
      }),
      invalidatesTags: (_result, _error, body) => [{ type: 'Builders', id: body.id }],
    }),

    deleteBuilder: build.mutation<unknown, number>({
      query: (id) => ({
        method: 'DELETE',
        url: `admin/developers/${id}`,
        body: {},
      }),
      invalidatesTags: (_result, _error, body) => [{ type: 'Builders' }],
    }),
  }),
});
