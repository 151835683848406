import { Map, YMaps } from '@pbe/react-yandex-maps';
import React, { useState } from 'react';

import { YMAPS_KEY, cityCordsOptions } from './helpers/constants';

console.log('YMAPS_KEY:', YMAPS_KEY);

interface EmptyYMaps {
  children: React.ReactNode;
  onSetPlacemark: (value: any) => void;
  values: any;
  setYMaps: (value: any) => void;
}

const EmptyYMaps = ({ children, onSetPlacemark, values, setYMaps }: EmptyYMaps) => {
  console.log('values:', values);

  const [city] = useState(cityCordsOptions[0].value);

  return (
    <div className="relative">
      <YMaps
        query={{
          apikey: YMAPS_KEY,
          load: 'geocode',
        }}
      >
        <Map
          width={'100%'}
          height={300}
          onClick={onSetPlacemark}
          onLoad={(e) => {
            console.log('e:', e);

            setYMaps(e);
          }}
          state={{
            center: values.lat ? [values.lat, values.lng] : city,
            zoom: values.lat ? 17 : 13,
          }}
          defaultState={{
            center: city,
            zoom: 13,
          }}
        >
          {children}
        </Map>
      </YMaps>
    </div>
  );
};

export default EmptyYMaps;
