import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { store } from '@store';
import { MuiCustomization, SnackbarCustomization } from '@components';
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';

// CSS
import './index.css';
import '@assets/fonts/index.css';

// initialization confifiles (yup)g
import '@utils';

// initialization swiper
import 'swiper/css/bundle';
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <MuiCustomization>
            <SnackbarCustomization>
              <CssBaseline />
              <App />
            </SnackbarCustomization>
          </MuiCustomization>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);
