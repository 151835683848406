/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect } from 'react';
import * as I from '@models';

const adsStorageName = 'adsID';
type Params = {
  data: I.AdsResponse | undefined;
};

export function useScrollToAds(params: Params) {
  const { data } = params;

  useEffect(() => {
    const id = sessionStorage.getItem(adsStorageName);
    if (data && id) {
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
      sessionStorage.removeItem(adsStorageName);
    }
  }, [data]);

  const handleSaveAdsID = useCallback(
    (id: string | number) => (_e: unknown) => {
      sessionStorage.setItem(adsStorageName, id.toString());
    },
    [],
  );

  return {
    handleSaveAdsID,
  };
}
