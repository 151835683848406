import * as I from '@models';

export class Cities {
  public static cities: I.City[] = [
    {
      value: 'dushanbe',
      name: 'Душанбе',
    },
    {
      value: 'khujand',
      name: 'Худжанд',
    },
    {
      value: 'bokhtar',
      name: 'Бохтар (Курган Тюбе)',
    },
    {
      value: 'buston',
      name: 'Бустон (Чкаловск)',
    },
    {
      value: 'vahdat',
      name: 'Вахдат',
    },
    {
      value: 'tursunzoda',
      name: 'Турсунзадa',
    },
    {
      value: 'rudaki',
      name: 'Рудаки (Ленский Район)',
    },
    {
      value: 'hisor',
      name: 'Гиссар',
    },
    {
      value: 'guliston',
      name: 'Гулистон (Кайраккум)',
    },
    {
      value: 'kulob',
      name: 'Кулоб',
    },
    {
      value: 'isfara',
      name: 'Исфара',
    },
    {
      value: 'norak',
      name: 'Нурек',
    },
    {
      value: 'konibodom',
      name: 'Канибадам',
    },
    {
      value: 'yovon',
      name: 'Яван',
    },
    {
      value: 'panjakent',
      name: 'Пянджикент',
    },
    {
      value: 'istaravshan',
      name: 'Истаравшан',
    },
    {
      value: 'farkhor',
      name: 'Фархор',
    },
    {
      value: 'danghara',
      name: 'Дангара',
    },
    {
      value: 'panj',
      name: 'Пяндж',
    },
    {
      value: 'vakhsh',
      name: 'Вахш',
    },
  ];

  public static getCityByValue(value: string) {
    return this.cities.find((city) => city.value === value);
  }

  public static getCityNameByValue(value: string) {
    const exist = this.cities.find((city) => city.value === value);
    return exist ? exist.name : '';
  }

  public static getCities() {
    return this.cities;
  }
}
