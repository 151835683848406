import React from 'react';
import { Grid, SxProps, Theme } from '@mui/material';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import MoveIcon from '@mui/icons-material/OpenWith';

interface Props {
  id: number;
  children: React.ReactNode;
}

export const SortableItem = (props: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: props.id });
  const styles: SxProps<Theme> = {
    transition,
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1,
    position: 'relative',
  };
  // RENDER
  return (
    <Grid item ref={setNodeRef} sx={styles}>
      <MoveIcon
        color="primary"
        {...listeners}
        {...attributes}
        sx={{ position: 'absolute', outline: 'none', left: 35, bottom: 15, zIndex: 1 }}
      />
      {props.children}
    </Grid>
  );
};
