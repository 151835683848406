import React, { useMemo, forwardRef } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import * as I from '@models';

export function ListItemLink(props: I.ListItemLinkProps) {
  const { icon, primary, to, ...restProps } = props;

  const renderLink = useMemo(
    () =>
      forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(function Link(itemProps, ref) {
        return (
          <NavLink
            {...itemProps}
            to={to}
            ref={ref}
            style={({ isActive }) => (isActive ? { backgroundColor: 'rgba(0, 0, 0, 0.04)' } : {})}
          />
        );
      }),
    [to],
  );

  return (
    <ListItem {...restProps} button component={renderLink}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={primary} />
    </ListItem>
  );
}
