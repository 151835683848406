/**
 * sort date
 * @param {string} dateString - date (2022-06-04T17:26:06.266Z)
 * @param {object} [options]
 * @returns {string|null} - sorted date (2022-06-04 17:26:06 | 2022-06-04 17:26:06)
 */
interface Options {
  time?: boolean;
}

export const sortingDate = (dateString = '', options?: Options) => {
  try {
    const time = options?.time;
    if (time) {
      return dateString.slice(0, 19).replace(/T/, ' ');
    }
    return dateString.slice(0, 10);
  } catch {
    return null;
  }
};
