/* eslint-disable react/jsx-key */
import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import logo from '@assets/images/pg-logo.svg';
import { drawerHeaderSX, logoSX } from './DrawerMenu.styles';

export const DrowerHeader = () => (
  <Box sx={drawerHeaderSX}>
    <Toolbar />
    <Box component="img" src={logo} alt="Parviz Group" sx={logoSX} />
  </Box>
);
