const rolesList = {
  MAIN: 'MAIN',
  SALES_MANAGER: 'SALESMANAGER',
  SITE_MANAGER: 'SITEMANAGER',
};

/**
 * Project roles
 * @type {{MAIN: string, SALES_MANAGER: string, SITE_MANAGER: string, ALL_ROLES: Array<string> }}
 */
export const roles = {
  ...rolesList,
  ALL_ROLES: Object.values(rolesList),
} as const;
