import React, { useMemo, useState } from 'react';
import * as I from '@models';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import BuildersIcon from '@mui/icons-material/Apartment';
import EmailIcon from '@mui/icons-material/Email';
import UsersIcon from '@mui/icons-material/Group';
import TarifsIcon from '@mui/icons-material/Assessment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import ExtensionIcon from '@mui/icons-material/Extension';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTypedDispatch, useTypedSelector } from '@hooks';
import { headerActions } from '@store';
import { routes } from '@utils';
import { HaveAccess } from '@components';
import { rootSX, desctopDrawerSX, mobileDrawerSX, innerItemSX } from './DrawerMenu.styles';
import { Collapse } from '@mui/material';
import { DrowerHeader } from './DrawerHeader';
import { ListItemLink } from './ListItemLink';
import { roles } from '@utils';
import { nanoid } from 'nanoid';
const { MAIN, SITE_MANAGER, SALES_MANAGER, ALL_ROLES } = roles;
const { mobileDrawerSwitcher } = headerActions;

// prettier-ignore
const drawerList: I.DrawerListTyped = [
  { id: nanoid(), to: routes.home, label: 'Главная', icon: <DashboardIcon />, onlyFor: ALL_ROLES },
  { id: nanoid(), to: routes.ads, label: 'Недвижимость', icon: <InboxIcon />, onlyFor: [MAIN, SITE_MANAGER] },
  { id: nanoid(), to: routes.auto, label: 'Авто', icon: <InboxIcon />, onlyFor: [MAIN, SITE_MANAGER] },
  { id: nanoid(), to: `${routes.ads}?filterBy=3`, label: 'Недвижимость', icon: <InboxIcon />, onlyFor: [SALES_MANAGER] },
  { id: nanoid(), to: `${routes.auto}?filterBy=3`, label: 'Авто', icon: <InboxIcon />, onlyFor: [SALES_MANAGER] },
  { id: nanoid(), to: routes.builders, label: 'Застройщики', icon: <BuildersIcon />, onlyFor: [MAIN, SITE_MANAGER] },
  { id: nanoid(), to: routes.smsMailing, label: 'SMS-рассылка', icon: <EmailIcon />, onlyFor: ALL_ROLES },
  { id: nanoid(), to: routes.users, label: 'Пользователи', icon: <UsersIcon />, onlyFor: [MAIN] },
  { id: nanoid(), to: routes.tarifs, label: 'Тарифы', icon: <TarifsIcon />, onlyFor: [MAIN] },
  { id: nanoid(), to: routes.clients, label: 'Клиенты', icon: <UsersIcon />, onlyFor: [MAIN] },
  { id: nanoid(), to: routes.tenders, label: 'Конкурсы', icon: <ExtensionIcon />, onlyFor: [MAIN] },
  { id: nanoid(), to: routes.messages, label: 'Сообщение', icon: <MarkUnreadChatAltIcon />, onlyFor: [MAIN, SITE_MANAGER] },
  { id: nanoid(), to: routes.callback, label: 'Ожидание звонка', icon: <PhoneCallbackIcon />, onlyFor: [MAIN, SITE_MANAGER] },
  { id: nanoid(), to: routes.feedbacks, label: 'Отзывы', icon: <FeedbackIcon />, onlyFor: [MAIN, SITE_MANAGER] },
  { id: nanoid(), to: routes.transactions, label: 'Транзакции', icon: <ReceiptLongIcon />, onlyFor: [MAIN] },
];

export function DrawerMenu(props: I.DrawerProps) {
  const { window } = props;
  const dispatch = useTypedDispatch();
  const showMobileDrawer = useTypedSelector(({ header }) => header.showMobileDrawer);
  const [openedCategories, setOpenedCategories] = useState<number[]>([]);
  const container = window !== undefined ? () => window().document.body : undefined;

  const handleCloseDrawer = () => {
    dispatch(mobileDrawerSwitcher(false));
  };

  const parser = useMemo(() => {
    const handleToggleCategories = (idx: number) => () => {
      if (openedCategories.includes(idx)) {
        setOpenedCategories((prev) => prev.filter((itemIdx) => itemIdx !== idx));
      } else {
        setOpenedCategories([...openedCategories, idx]);
      }
    };

    return drawerList.map((element, idx, arr) => {
      const divider = idx !== arr.length - 1 && <Divider />;

      // Category with elelments
      if (Array.isArray(element)) {
        const [{ id, label, icon, onlyFor }, list] = element;
        const openCategory = openedCategories.includes(idx);
        return (
          <HaveAccess onlyFor={onlyFor} key={id}>
            <ListItem disablePadding>
              <ListItemButton onClick={handleToggleCategories(idx)}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={label} />
                {openCategory ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>

            <Collapse in={openCategory} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {list.map(({ to, label, icon, onlyFor }) => (
                  <HaveAccess onlyFor={onlyFor} key={label}>
                    <ListItemLink primary={label} to={to} icon={icon} sx={innerItemSX} />
                  </HaveAccess>
                ))}
              </List>
            </Collapse>
            {divider}
          </HaveAccess>
        );
      }
      // Elelment
      const { id, to, label, icon, onlyFor } = element;
      return (
        <HaveAccess onlyFor={onlyFor} key={id}>
          <ListItemLink primary={label} to={to} icon={icon} />
          {divider}
        </HaveAccess>
      );
    });
  }, [openedCategories, handleCloseDrawer]);

  // RENDER
  return (
    <Box component="nav" sx={rootSX} aria-label="mailbox folders">
      <Drawer variant="permanent" sx={desctopDrawerSX} open>
        <Box>
          <DrowerHeader />
          <Divider />
          {parser}
        </Box>
      </Drawer>

      {/* mobile drawer */}
      <Drawer
        container={container}
        variant="temporary"
        open={showMobileDrawer}
        onClose={handleCloseDrawer}
        ModalProps={{ keepMounted: true }}
        sx={mobileDrawerSX}
      >
        <Box>
          <DrowerHeader />
          <Divider />
          {parser}
        </Box>
      </Drawer>
    </Box>
  );
}
