import { SxProps, useScrollTrigger, Theme } from '@mui/material';
import { drawerWidth } from '@utils';
import { cloneElement } from 'react';

export function ElevationScroll(props: { window?: () => Window; children: React.ReactElement }) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });
  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
    sx: { borderBottom: (theme: Theme) => (trigger ? 'none' : `1px solid ${theme.palette.divider}`) },
  });
}

export const appBarSX: SxProps = {
  width: { sm: `calc(100% - ${drawerWidth}px)` },
  ml: { sm: `${drawerWidth}px` },
  boxShadow: 'none',
};

export const toggleButtonSX = {
  mr: 2,
  display: { sm: 'none' },
};

export const leftBoxSX = {
  flexGrow: 1,
};
