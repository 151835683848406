import * as I from '@models';

export class Districts {
  private static districts: I.District[] = [
    {
      name: 'Исмоили Сомони',
      value: 'ismoil_somoni',
    },
    {
      name: 'Сино',
      value: 'sino',
    },
    {
      name: 'Фирдавси',
      value: 'firdavsi',
    },
    {
      name: 'Шохмансур',
      value: 'shohmansur',
    },
    {
      name: 'Рудаки',
      value: 'rudaki',
    },
  ];

  static getDistricts() {
    return this.districts;
  }

  static getDistrictByValue(value: string, options?: { returnNoResult: boolean }) {
    const returnNoResult = options?.returnNoResult;
    const result = this.districts.find((district) => district.value === value);
    if (returnNoResult) {
      return result || { name: 'Не указано', value: '' };
    }
    return result;
  }
}
