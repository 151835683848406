import { createApiWrapped } from '../api/api';
import * as I from '@models';

export const homeApi = createApiWrapped.injectEndpoints({
  endpoints: (build) => ({
    getAdminExchange: build.query<I.AdminExchangeResponse, void>({
      query: () => 'admin/current-exchange-rate',
      providesTags: ['AdminExchange'],
    }),

    editAdminExchange: build.mutation<unknown, I.AdminExchangeEdit>({
      query: (body) => ({
        method: 'POST',
        url: 'admin/update-exchange-rate',
        body,
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: ['AdminExchange'],
    }),

    getGeneralStatistic: build.query<{ clientsCount: number }, void>({
      query: () => 'admin/get-general-statistic',
    }),
  }),
});
