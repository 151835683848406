export const titleSX = {
  py: 4,
  color: '#6c757d',
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  '& img': {
    display: 'inline-block',
    width: 35,
    marginRight: 1.2,
  },
};
