import { createApiWrapped } from '../api/api';
import * as I from '@models';

export const headerApi = createApiWrapped.injectEndpoints({
  endpoints: (build) => ({
    changePassword: build.mutation<string, I.HeaderPassword>({
      query: (data) => ({
        method: 'POST',
        url: 'user/update-password',
        body: data,
        responseHandler(response) {
          if (response.ok) {
            return response.text();
          }
          return response.json();
        },
      }),
    }),
  }),
});
