import * as I from '@models';
import { createApiWrapped } from '../api/api';

export const clientsApi = createApiWrapped.injectEndpoints({
  endpoints: (build) => ({
    // here request's type should be 'GET'! but backend dev didn't do it
    getClients: build.query<I.ClientsResponse, void | I.ClientsFilters>({
      query: (params) => {
        return {
          method: 'POST',
          url: 'admin/clients/get-by-filter',
          body: { ...params, limit: 10, offset: ((params?.page || 1) - 1) * 10 },
        };
      },
      transformResponse: (response: { data: I.ClientsResponse }) => response.data,
    }),

    // get clients item
    getClientsItem: build.query<I.ClientDetail, string | number>({
      query: (clientID) => ({
        url: `admin/clients/${clientID}`,
      }),
      providesTags: (_result, _error, adsID) => [{ type: 'ClientsItem', id: adsID }],
      transformResponse: (response: { data: I.ClientDetail }) => response.data,
    }),

    // edit ads item
    clientBalanceTransfer: build.mutation<void, I.ClientBalanceTransfer>({
      query: ({ id, ...body }) => ({
        method: 'POST',
        url: `admin/clients/${id}/update-balance`,
        body,
      }),
      invalidatesTags: (_result, _error, body) => [{ type: 'ClientsItem', id: body.id }],
    }),

    // here request's type should be 'GET'! but backend dev didn't do it
    getClientsStatistics: build.query<I.ClientsStatisticsResponse, I.ClientsStatisticsFilters>({
      query: (params) => {
        const { clientID, page = 1, ...restParams } = params;
        return {
          method: 'POST',
          url: `admin/clients/${clientID}/transactions/get-by-filter`,
          body: { ...restParams, limit: 10, offset: (page - 1) * 10 },
        };
      },
      transformResponse: (response: { data: I.ClientsStatisticsResponse }) => response.data,
    }),
  }),
});
