import * as I from '@models';

export class Category {
  private static categories: I.CategoryLocal[] = [
    {
      name: 'Вторички',
      value: 'old_flat',
      logo: `${process.env.PUBLIC_URL}/assets/categories/old_flat.jpg`,
      rentCount: 0,
      sellCount: 0,
      description: 'Продажа / Аренда по доступным ценам в хороших домах',
    },
    {
      name: 'Квартиры в новостройках',
      value: 'new_flat',
      logo: `${process.env.PUBLIC_URL}/assets/categories/new_flat.jpg`,
      rentCount: 0,
      sellCount: 0,
      description:
        'Продажа / Аренда по выгодным ценам, кредиты до 24 месяцев без процентов, кредит до 7 лет через банк',
    },
    {
      name: 'Дома и дачи',
      value: 'houses_and_cottages',
      logo: `${process.env.PUBLIC_URL}/assets/categories/houses_and_cottages.jpg`,
      rentCount: 0,
      sellCount: 0,
      description: 'Продажа / Аренда / Покупка домов в городе, а также загородных объектов по доступным ценам',
    },
    {
      name: 'Офисы',
      value: 'office',
      logo: `${process.env.PUBLIC_URL}/assets/categories/office.jpg`,
      rentCount: 0,
      sellCount: 0,
      description: 'Продажа /Аренда множество предложений любого формата по низким ценам',
    },
    {
      name: 'Торговая площадь',
      value: 'trade_area',
      logo: `${process.env.PUBLIC_URL}/assets/categories/trade_square.jpg`,
      rentCount: 0,
      sellCount: 0,
      description: 'Продажа / Аренда по выгодным ценам под любой бизнес',
    },
    {
      name: 'Готовый бизнес',
      value: 'ready_business',
      logo: `${process.env.PUBLIC_URL}/assets/categories/ready_business.jpg`,
      rentCount: 0,
      sellCount: 0,
      description: 'Продажа / Аренда (рестораны, компьютерные клубы, магазины, салоны и многое другое)',
    },
    {
      name: 'Помещения свободного назначения',
      value: 'free_appointment',
      logo: `${process.env.PUBLIC_URL}/assets/categories/free_planning.jpg`,
      rentCount: 0,
      sellCount: 0,
      description: 'Продажа/Аренда свободной площади под любой бизнес',
    },
    {
      name: 'Производство',
      value: 'production',
      logo: `${process.env.PUBLIC_URL}/assets/categories/factory.jpg`,
      rentCount: 0,
      sellCount: 0,
      description: 'Продажа/Аренда (фабрики, заводы, производства и множество другого)',
    },
    {
      name: 'Склады',
      value: 'warehouse',
      logo: `${process.env.PUBLIC_URL}/assets/categories/warehouse.jpg`,
      rentCount: 0,
      sellCount: 0,
      description: 'Продажа/ Аренда складских помещений по доступным ценам',
    },
  ];

  private static autoCategories: I.CategoryLocal[] = [
    {
      name: 'Вторички',
      value: 'old_flat',
      logo: `${process.env.PUBLIC_URL}/assets/categories/old_flat.jpg`,
      rentCount: 0,
      sellCount: 0,
      description: 'Продажа / Аренда по доступным ценам в хороших домах',
    },
    {
      name: 'Квартиры в новостройках',
      value: 'new_flat',
      logo: `${process.env.PUBLIC_URL}/assets/categories/new_flat.jpg`,
      rentCount: 0,
      sellCount: 0,
      description:
        'Продажа / Аренда по выгодным ценам, кредиты до 24 месяцев без процентов, кредит до 7 лет через банк',
    },
  ];

  static getCategories() {
    return this.categories;
  }

  static getCategoryByValue(value: string, isAuto?: boolean) {
    const list = isAuto ? this.autoCategories : this.categories;
    return list.find((cat) => cat.value === value);
  }

  static getConvertedCategories(categories: I.Category[], isAuto?: boolean): I.ConvertedCategory[] {
    return categories
      .filter((category) => !!this.getCategoryByValue(category.name, isAuto))
      .map((category) => {
        const translation = Category.getCategoryByValue(category.name, isAuto);
        return {
          name: translation ? translation.name : category.name,
          id: category.categoryId,
        };
      });
  }
}
