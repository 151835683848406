import React, { memo, useCallback } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { rootSX } from './BackToTop.styles';
import { moveUp } from '@utils';
import { Fab, useScrollTrigger, Zoom, Box } from '@mui/material';
const globalObject = window;

function BackToTopMemorized() {
  const trigger = useScrollTrigger({
    target: globalObject,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = useCallback(() => {
    moveUp();
  }, []);

  return (
    <Zoom in={trigger}>
      <Box onClick={handleClick} role="presentation" sx={rootSX}>
        <Fab color="secondary" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </Box>
    </Zoom>
  );
}

export const BackToTop = memo(BackToTopMemorized);
