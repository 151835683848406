import React, { useState } from 'react';
import * as I from '@models';
import ResetIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Tune';
import { Box, Button, Collapse, Paper, Stack } from '@mui/material';

export function FilterPanel(props: I.FilterPanelProps) {
  const { onSubmit, onReset, children, openDefault = false, headChildren } = props;
  const [open, setOpen] = useState(openDefault);

  // suearch btn / submit
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(e);
  };

  // reset btn
  const handleReset = (e: React.MouseEvent<HTMLButtonElement>) => {
    onReset(e);
  };

  // toggle
  const handleToggle = () => {
    setOpen(!open);
  };

  // RENDER
  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <Stack display="inline-flex" spacing={2}>
          <Button onClick={handleToggle} color="primary" startIcon={<SettingsIcon />} fullWidth>
            Фильтры
          </Button>

          <Button onClick={handleReset} color="primary" startIcon={<ResetIcon />} fullWidth>
            Сбросить
          </Button>

          <Button type="submit" color="primary" startIcon={<SearchIcon />} fullWidth>
            Поиск
          </Button>
        </Stack>

        {headChildren}
      </Stack>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box mt={2} p={3} component={Paper} elevation={0}>
          {children}
        </Box>
      </Collapse>
    </Box>
  );
}
