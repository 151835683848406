export const routes = {
  home: '/',
  login: '/login',
  ads: '/ads',
  adsAdd: '/ads/add',
  auto: '/auto',
  addAuto: '/auto/add-auto',
  autoEdit: '/auto/edit',
  adsEdit: '/ads/edit',
  builders: '/builders',
  buildersEdit: '/builders/edit',
  buildersAdd: '/builders/add',
  smsMailing: '/sms-mailing',
  users: '/users',
  tarifs: '/tarifs',
  tarifsAdd: '/tarifs/add',
  tarifsEdit: '/tarifs/edit',
  clients: '/clients',
  clientsBalance: '/clients/balance-transfer',
  clientsStatistics: '/clients/statistics',
  clientsTransactions: '/clients/transactions',
  tenders: '/tenders',
  tendersAdd: '/tenders/add',
  tendersEdit: '/tenders/edit',
  messages: '/messages',
  callback: '/callback',
  feedbacks: '/feedbacks',
  transactions: '/transactions',
} as const;
