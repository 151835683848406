import * as I from '@models';
import { createApiWrapped } from '../api/api';

export const tarifsApi = createApiWrapped.injectEndpoints({
  endpoints: (build) => ({
    // here request's type should be 'GET'! but backend dev didn't do it
    getTarifs: build.query<I.TarifsResponse, I.TarifsFilters>({
      query: (params) => {
        const { page = 1, ...restParams } = params;
        return {
          method: 'POST',
          url: 'tariff/get-by-filter',
          body: { ...restParams, limit: 10, offset: (page - 1) * 10 },
        };
      },
      transformResponse: (response: { data: I.TarifsResponse }) => response.data,
      providesTags: ['Tarifs'],
    }),

    getTarifsItem: build.query<I.Tarif, string | number>({
      query: (tarifID) => ({
        url: `tariff/${tarifID}`,
      }),
      transformResponse: (response: { data: I.Tarif }) => response.data,
      providesTags: ['TarifsItem'],
    }),

    addTarif: build.mutation<unknown, I.TarifLocal>({
      query: (body) => ({
        method: 'POST',
        url: `tariff`,
        body,
      }),
      invalidatesTags: ['Tarifs'],
    }),

    editTarif: build.mutation<unknown, I.TarifLocal>({
      query: (body) => ({
        method: 'PUT',
        url: `tariff/${body.id}`,
        body,
      }),
      invalidatesTags: ['TarifsItem'],
    }),

    deleteTarif: build.mutation<unknown, number>({
      query: (tarifID) => ({
        method: 'DELETE',
        url: `tariff/${tarifID}`,
      }),
      invalidatesTags: ['Tarifs'],
    }),
  }),
});
