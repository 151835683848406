export interface ICityCordOption {
  label: string;
  value: [number, number];
  id: number;
}

export const cityCordsOptions: ICityCordOption[] = [
  {
    label: 'Душанбе',
    value: [38.556633, 68.8141359],
    id: 1,
  },
  {
    label: 'Истаравшан',
    value: [39.9142, 69.0033],
    id: 2,
  },
  {
    label: 'Канибадам',
    value: [40.2941, 70.4312],
    id: 3,
  },
  {
    label: 'Куляб',
    value: [37.9146, 69.7845],
    id: 4,
  },
  {
    label: 'Кургана Тюбе',
    value: [37.834, 68.7819],
    id: 5,
  },
  {
    label: 'Худжанд',
    value: [40.2826, 69.6222],
    id: 6,
  },
];

export const YMAPS_KEY = '35223f38-e2c8-4523-b0d4-bee685b3821a';
