import * as I from '@models';
import { createApiWrapped } from '../api/api';

export const SMSMailingApi = createApiWrapped.injectEndpoints({
  endpoints: (build) => ({
    // here request's type should be 'GET'! but backend dev didn't do it
    getAllClients: build.query<I.SMSMailingClient[], void>({
      query: () => ({
        method: 'POST',
        url: 'admin/clients/get-by-filter',
        body: { phoneNumber: '' },
      }),
      transformResponse: (response: { data: I.SMSMailingResponse }) => response.data.list || [],
    }),

    sendMessage: build.mutation<void, I.SMSMailingSendMessage>({
      query: (body) => ({
        method: 'POST',
        url: 'admin/sms-sending',
        body,
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
});
