import React from 'react';
import { SxProps } from '@mui/material';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { DraggableAttributes } from '@dnd-kit/core';

type ChildrenProps = {
  attributes: DraggableAttributes;
  listeners?: SyntheticListenerMap;
  setNodeRef: (node: HTMLElement | null) => void;
  nodeSX: SxProps;
};

interface Props {
  id: number;
  children: (props: ChildrenProps) => React.ReactNode;
}

export const SortableItem = (props: Props) => {
  const { children, id } = props;
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const nodeSX: SxProps = {
    transition,
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? 'speedDial' : 'auto',
    boxShadow: isDragging ? 5 : 0,
    position: 'relative',
  };

  // RENDER
  return (
    <>
      {children({
        attributes,
        listeners,
        setNodeRef,
        nodeSX,
      })}
    </>
  );
};
