import React, { memo, useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { useCallback } from 'react';
import { useMemo } from 'react';
import * as I from '@models';

type Props = {
  InputProps?: object;
  eyeColor?: I.MuiVariants;
  disabled?: boolean;
} & TextFieldProps;

function PasswordFieldMemorized(props: Props) {
  const { InputProps, eyeColor = 'default', disabled = false, ...restsProps } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleMouseDownPassword = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
  }, []);

  // prettier-ignore
  const InputPropsMemorized = useMemo(() => ({
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
          color={eyeColor}
          disabled={disabled}
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    ),
    ...InputProps,
  }),[InputProps, eyeColor, handleClickShowPassword, handleMouseDownPassword, disabled, showPassword]);

  // RENDER
  return (
    <TextField
      {...restsProps}
      disabled={disabled}
      InputProps={InputPropsMemorized}
      type={showPassword ? 'text' : 'password'}
    />
  );
}

export const PasswordField = memo(PasswordFieldMemorized);
