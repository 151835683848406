import React from 'react';
import { Box, Stack, Toolbar } from '@mui/material';
import { BackToTop } from 'components/forms';
import { Header } from '../Header/Header';
import { DrawerMenu } from '../DrawerMenu/DrawerMenu';
import { mainSX } from './Wrapper.styles';

function WrapperMemorized({ children }: { children: React.ReactNode }) {
  // RENDER
  return (
    <Stack>
      <Header />
      <DrawerMenu />
      <Box component="main" sx={mainSX}>
        <Toolbar />
        {children}
      </Box>
      <BackToTop />
    </Stack>
  );
}

export const Wrapper = React.memo(WrapperMemorized);
