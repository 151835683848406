import React, { memo } from 'react';
import * as I from '@models';
import { commonApi } from '@store';
const { useGetUserQuery } = commonApi;

function HaveAccessMemorized(props: I.HaveAccessProps) {
  const { children, onlyFor } = props;
  const { data: user } = useGetUserQuery();

  if (user && onlyFor.includes(user.role)) {
    return <>{children}</>;
  }
  return null;
}

export const HaveAccess = memo(HaveAccessMemorized);
