import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { createApiWrapped, createTendersApiWrapped } from './api/api';
import { loginApi } from './login/login.api';
import { headerReducer } from './header/header.slice';

export const store = configureStore({
  reducer: {
    [loginApi.reducerPath]: loginApi.reducer,
    [createApiWrapped.reducerPath]: createApiWrapped.reducer,
    [createTendersApiWrapped.reducerPath]: createTendersApiWrapped.reducer,
    header: headerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loginApi.middleware, createApiWrapped.middleware, createTendersApiWrapped.middleware),
});

setupListeners(store.dispatch);
