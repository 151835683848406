import React, { useEffect } from 'react';
import * as YUP from 'yup';
import { LoadingButton } from '@mui/lab';
import { useTypedDispatch, useTypedSelector, useWrappedFormik } from '@hooks';
import { headerActions, headerApi } from '@store';
import { PasswordField } from 'components/forms';
import { getResponseErrorMessage } from '@utils';
import { useSnackbar } from 'notistack';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const { passwordDialogSwitcher } = headerActions;
const { useChangePasswordMutation } = headerApi;

const validationSchema = YUP.object({
  password: YUP.string().required(),
  confirmPassword: YUP.string()
    .oneOf([YUP.ref('password')], 'Пароли не совпадают')
    .required(),
});

function ChangePasswordMemorized() {
  const dispatch = useTypedDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const open = useTypedSelector(({ header }) => header.showPasswordDialog);
  const [changePasswordSubmit, { isLoading, isSuccess, data, isError, error }] = useChangePasswordMutation();
  const formik = useWrappedFormik({
    validationSchema,
    initialValues: { password: '', confirmPassword: '' },
    onSubmit(data) {
      changePasswordSubmit(data);
    },
  });
  const { getErrorState, getErrorMessage, getFieldProps, handleSubmit, handleReset } = formik;

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      enqueueSnackbar(data || '', { variant: 'success' });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) enqueueSnackbar(getResponseErrorMessage(error), { variant: 'error' });
  }, [isError]);

  const handleClose = () => {
    dispatch(passwordDialogSwitcher(false));
    handleReset('');
  };

  // RENDER
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle>Изменение пароля</DialogTitle>
        <DialogContent>
          <PasswordField
            {...getFieldProps('password')}
            error={getErrorState('password')}
            helperText={getErrorMessage('password')}
            disabled={isLoading}
            label="Новый пароль *"
          />
          <PasswordField
            {...getFieldProps('confirmPassword')}
            error={getErrorState('confirmPassword')}
            helperText={getErrorMessage('confirmPassword')}
            disabled={isLoading}
            label="Подтвердите пароль *"
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton type="submit" variant="contained" loading={isLoading}>
            Изменить
          </LoadingButton>
          <Button onClick={handleClose} color="inherit">
            Закрыть
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export const ChangePassword = React.memo(ChangePasswordMemorized);
