import React from 'react';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { appBarSX, toggleButtonSX, leftBoxSX, ElevationScroll } from './Header.styles';
import { useTypedDispatch } from '@hooks';
import { headerActions } from '@store';
import { UserProfile } from './UserProfile';
const { mobileDrawerSwitcher } = headerActions;

export function Header() {
  const dispatch = useTypedDispatch();

  const handleDrawerToggle = () => {
    dispatch(mobileDrawerSwitcher(true));
  };

  // RENDER
  return (
    <ElevationScroll>
      <AppBar position="fixed" sx={appBarSX} color="inherit">
        <Toolbar>
          <Box sx={leftBoxSX}>
            <IconButton
              aria-label="open drawer"
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={toggleButtonSX}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <UserProfile />
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
}
