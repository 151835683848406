import React from 'react';
import { useEffect } from 'react';

export function useDocumentTitle(title: string) {
  const handleChangeDocumentTitle = React.useCallback((title: string) => {
    document.title = title;
  }, []);

  useEffect(() => {
    if (title) {
      handleChangeDocumentTitle(title);
    }
  }, [title, handleChangeDocumentTitle]);

  return handleChangeDocumentTitle;
}
