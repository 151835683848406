import React, { memo, useMemo } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';

function SearchFieldMemorized(props: TextFieldProps) {
  // prettier-ignore
  const InputPropsMemorized = useMemo(() => ({
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>
    ),
  }),[]);

  // RENDER
  return <TextField {...props} InputProps={InputPropsMemorized} />;
}

export const SearchField = memo(SearchFieldMemorized);
