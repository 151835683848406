import React, { useState } from 'react';
import InfoIcon from '@mui/icons-material/Visibility';
import { Backdrop, Box, IconButton } from '@mui/material';

interface Props {
  imgURL: string;
}

export function ImagePreview(props: Props) {
  const { imgURL } = props;
  const [showDialog, setShowDialog] = useState(false);

  const hadleDialogToggle = () => {
    setShowDialog((prev) => !prev);
  };

  // RENDER
  return (
    <>
      <IconButton onClick={hadleDialogToggle}>
        <InfoIcon />
      </IconButton>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.tooltip }} open={showDialog} onClick={hadleDialogToggle}>
        <Box component="img" src={imgURL} />
      </Backdrop>
    </>
  );
}
