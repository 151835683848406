import React from 'react';
import { moveUp } from '@utils';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';

const theme = createTheme({
  // TYPOGRAPHY
  typography: {
    fontSize: 14,

    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },

  shape: {
    borderRadius: 8,
  },

  // PALETTE
  palette: {
    primary: {
      main: '#F05423',
    },
    secondary: {
      main: '#525252',
    },
  },

  // COMPONENTS
  components: {
    // pagination
    MuiPagination: {
      defaultProps: {
        color: 'primary',
        onKeyDown: (e) => {
          if (e.key === 'Enter') {
            moveUp();
          }
        },
        onMouseUp: () => {
          moveUp();
        },
      },
    },

    // container
    MuiContainer: {
      defaultProps: {
        fixed: true,
      },
    },

    // stack
    MuiStack: {
      defaultProps: {
        direction: 'row',
      },
    },

    // button
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
    },

    // form control
    MuiFormControl: {
      defaultProps: {
        margin: 'normal',
        size: 'small',
        fullWidth: true,
      },
    },

    // icon button
    MuiIconButton: {
      defaultProps: {
        color: 'primary',
      },
    },

    // field
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'normal',
        size: 'small',
        fullWidth: true,
      },
    },

    // dialog
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: 'xs',
      },
    },

    // dialog content
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingBottom: 0,
        },
      },
    },

    // dialog action
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },

    // link
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        variant: 'body2',
        sx: { cursor: 'pointer' },
      },
    },

    // Table Container
    // MuiTableContainer: {
    //   defaultProps: {
    //     component: Paper,
    //   },
    // },

    // Typography
    MuiTypography: {
      defaultProps: {
        gutterBottom: true,
        variant: 'subtitle1',
      },
    },

    // Select
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            sx: {
              maxHeight: 300,
              my: 1,
              '&::-webkit-scrollbar': { width: 10, backgroundColor: '#F5F5F5' },
              '&::-webkit-scrollbar-thumb': { backgroundColor: '#D8D8D8' },
            },
          },
        },
      },
    },

    // Skeleton
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },
    },

    // Autocomplete
    MuiAutocomplete: {
      defaultProps: {
        fullWidth: true,
        loadingText: 'Загрузка…',
        noOptionsText: 'Нет данных',
        PaperComponent: (props) => <Box component={Paper} my={1} {...props} />,
      },
      styleOverrides: {
        listbox: {
          '&::-webkit-scrollbar': { width: 10, backgroundColor: '#F5F5F5' },
          '&::-webkit-scrollbar-thumb': { backgroundColor: '#D8D8D8' },
        },
      },
    },
  },
});

function MuiCustomizationMemorized(props: { children: React.ReactNode }) {
  const { children } = props;
  return <ThemeProvider theme={responsiveFontSizes(theme)}>{children}</ThemeProvider>;
}

export const MuiCustomization = React.memo(MuiCustomizationMemorized);
