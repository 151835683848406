import { SxProps } from '@mui/material';
import { drawerWidth } from '@utils';

export const mainSX: SxProps = {
  flexGrow: 1,
  py: 5,
  px: 3,
  width: { sm: `calc(100% - ${drawerWidth}px)` },
  minHeight: '100vh',
  // backgroundColor: '#f7f9fc',
  backgroundColor: '#f5f5f5',
};
