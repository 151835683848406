/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as I from '@models';
import { routes, removeToken, getToken } from '@utils';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

const getBaseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', token);
      }
      return headers;
    },
    paramsSerializer: (params) => {
      const searchParams = new URLSearchParams(params);
      for (const [key, value] of searchParams) {
        if (value === '') {
          searchParams.delete(key);
        }
      }
      return searchParams.toString();
    },
  });

const getBaseQueryWithLogout = (baseUrl: string) =>
  (async (args, api, extraOptions) => {
    const baseQuery = getBaseQuery(baseUrl);
    const result = await baseQuery(args, api, extraOptions);
    // logout
    if (result.error && result.error.status === 401) {
      removeToken();
      document.location.replace(routes.login);
    }
    return result;
  }) as BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError | I.ApiErrorResponse>;

export const createApiWrapped = createApi({
  reducerPath: 'app/api',
  baseQuery: getBaseQueryWithLogout(process.env.REACT_APP_BASE_URL!),
  refetchOnReconnect: true,
  tagTypes: [
    'Ads',
    'AdsItem',
    'Counters',
    'AdminExchange',
    'Builders',
    'Users',
    'Tarifs',
    'TarifsItem',
    'Clients',
    'ClientsItem',
  ],
  endpoints: () => ({}),
});

export const createTendersApiWrapped = createApi({
  reducerPath: 'tenders/api',
  baseQuery: getBaseQueryWithLogout(process.env.REACT_APP_TENDERS_BASE_URL!),
  refetchOnReconnect: true,
  tagTypes: ['Tenders', 'TenderItem'],
  endpoints: () => ({}),
});
