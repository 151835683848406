import React, { memo, useEffect, useMemo } from 'react';
import { CircularProgress, Backdrop, alpha, useTheme } from '@mui/material';
const body = document.querySelector('body');

interface Props {
  open?: boolean;
  backdropColor?: string;
  backdropOpacity?: number;
}

function setBodyStyles() {
  if (body) {
    const pr = window.innerWidth - document.documentElement.clientWidth + 'px';
    body.style.paddingRight = pr;
    body.style.overflowY = 'hidden';
  }
}

function resetBodyStyles() {
  if (body) {
    body.style.paddingRight = '';
    body.style.overflowY = '';
  }
}

function WindowLoaderMemorized(props: Props) {
  const { open = true, backdropColor = '#fff', backdropOpacity = 0.5, ...restProps } = props;
  const theme = useTheme();

  // prettier-ignore
  const styles = useMemo(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: theme.zIndex.tooltip,
    backgroundColor: alpha(backdropColor, backdropOpacity),
  }), [theme.zIndex.tooltip, backdropColor, backdropOpacity]);

  useEffect(() => {
    if (open) {
      setBodyStyles();
    }
    return resetBodyStyles;
  });

  // RENDER
  if (open) {
    return (
      <Backdrop open={open} sx={styles}>
        <CircularProgress size={60} color="primary" {...restProps} />
      </Backdrop>
    );
  }

  return null;
}

export const WindowLoader = memo(WindowLoaderMemorized);
