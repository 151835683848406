import jwt_decode from 'jwt-decode';
import * as I from '@models';
const tokenVariableName = 'token';

/**
 * Save access token on localStorage
 * @param {string} access_token - Access token
 */
export function setToken(access_token: string) {
  localStorage.setItem(tokenVariableName, `Bearer ${access_token}`);
}

/**
 * Return access and refresh roken
 * @returns {string} - get tokens from localStorage
 */
export function getToken(): string {
  return localStorage.getItem(tokenVariableName) || '';
}

/**
 * Remove tokens from localStorage
 */
export function removeToken() {
  localStorage.removeItem(tokenVariableName);
}

/**
 * Parse access token and return it (or empty object - {})
 * @returns {Object}
 */
export function tokenParser(): I.TokenData {
  try {
    return jwt_decode(getToken());
  } catch {
    return {};
  }
}
