import { createApiWrapped } from '../api/api';
import * as I from '../../models';

export const commonApi = createApiWrapped.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<I.AuthUserResponse, null | void>({
      query: () => 'admin/current-admin',
    }),
    getCategories: build.query<I.CategoriesResponse, void>({
      query: () => 'real-estates/categories',
      transformResponse: (response: any) => response.list,
    }),
    getAutoCategories: build.query<I.CategoriesResponse, void>({
      query: () => 'cars/categories',
      transformResponse: (response: any) => response.list,
    }),
    getAdmins: build.query<I.AdminsResponse, null | void>({
      query: () => 'admin/get-admins',
    }),
    getCounters: build.query<I.CountersResponse, void | null>({
      query: () => 'admin/real-estates/counters',
      providesTags: ['Counters'],
    }),
    getExchange: build.query<I.ExchangeResponse, void>({
      query: () => 'exchange',
      transformResponse: (response: { data: I.ExchangeResponse }) => response.data,
    }),
    uploadPhoto: build.mutation<I.UploadPhotoResponse, Blob | string>({
      query: (blobFile) => {
        const formData = new FormData();
        formData.append('upload', blobFile);
        return {
          method: 'POST',
          url: 'uploads/upload-image',
          body: formData,
        };
      },
    }),
  }),
});
