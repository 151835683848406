import * as I from '@models';
import { createTendersApiWrapped } from 'store/api/api';

export const tendersApi = createTendersApiWrapped.injectEndpoints({
  endpoints: (build) => ({
    getTenders: build.query<I.TendersResponse, void>({
      query: () => 'contests',
      providesTags: ['Tenders'],
    }),

    getTenderItem: build.query<I.TendersItemResponse, string | number>({
      query: (tenderID) => ({
        url: `contest/${tenderID}`,
      }),
      providesTags: ['TenderItem'],
    }),

    addTender: build.mutation<unknown, I.Tender>({
      query: (body) => ({
        method: 'POST',
        url: 'contest',
        body,
      }),
      invalidatesTags: ['Tenders', 'TenderItem'],
    }),

    editTender: build.mutation<unknown, I.Tender>({
      query: (body) => ({
        method: 'PUT',
        url: 'contest',
        body,
      }),
      invalidatesTags: ['Tenders', 'TenderItem'],
    }),

    deleteTender: build.mutation<unknown, number>({
      query: (ternderID) => ({
        method: 'DELETE',
        url: `contest/${ternderID}`,
      }),
      invalidatesTags: ['Tenders'],
    }),
  }),
});
