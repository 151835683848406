import * as I from '@models';
import { createApiWrapped } from '../api/api';

export const usersApi = createApiWrapped.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<I.UserResponse[], void>({
      query: () => 'admin/get-admins',
      providesTags: ['Users'],
    }),

    addUser: build.mutation<void, I.User>({
      query: (body) => ({
        method: 'POST',
        url: 'admin/create-or-update-admin',
        body,
        responseHandler(response) {
          return response.status === 500 ? Promise.reject('Номер телефон уже привязана') : response.json();
        },
      }),
      invalidatesTags: (_result, error) => (error ? [] : ['Users']),
    }),

    editUser: build.mutation<void, I.User>({
      query: (body) => ({
        method: 'POST',
        url: 'admin/create-or-update-admin',
        body,
      }),
      invalidatesTags: (_result, error) => (error ? [] : ['Users']),
    }),

    reassignUserAds: build.mutation<void, I.UserReassignAd>({
      query: (body) => ({
        method: 'POST',
        url: 'admin/transfer-ads',
        body,
      }),
    }),
  }),
});
