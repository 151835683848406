import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { Skeleton } from '@mui/material';

type Props = {
  url: string;
  alt?: string;
  width?: string | number;
  height?: string | number;
};

type ImageType = null | HTMLImageElement;

const ImageDownloaderMemorized: React.FC<Props> = (props) => {
  const { url, alt, width = '100%', height = '100%' } = props;
  const [showSkeleton, setShowSkeleton] = useState(false);
  const imageRef = useRef<ImageType>(null);

  useEffect(() => {
    const handleShowImage = () => {
      setShowSkeleton(true);
      if (imageRef.current) {
        imageRef.current.src = url;
        imageRef.current.style.opacity = '0';
      }
    };
    const handleHideImage = () => {
      setShowSkeleton(false);
      if (imageRef.current) {
        imageRef.current.style.opacity = '1';
      }
    };
    try {
      if (!imageRef.current) {
        return;
      }
      handleShowImage();
      imageRef.current.onload = () => {
        handleHideImage();
      };
      imageRef.current.onerror = () => {
        if (imageRef.current) {
          handleHideImage();
        }
      };
    } catch (e) {
      const error = e as Error;
      console.log(`
      Something went wrong!
      component: ImageDownloader
      message: ${error.message}
      `);
    }
    // calcel the request
    return () => {
      imageRef.current?.src && (imageRef.current.src = '');
    };
  }, [url]);

  const rootOptions = {
    sx: {
      width,
      height,
      position: 'relative',
      overflow: 'hidden',
    },
  };

  const imageOptions = {
    loading: 'lazy',
    alt: alt || 'Не удалось...',
    sx: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      pointerEvents: 'none',
      transition: 'opacity 200ms',
    },
  };

  const skeletonOptions = {
    sx: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  };

  // RENDER
  if (!url) {
    return null;
  }

  return (
    <Box {...rootOptions}>
      <Box {...imageOptions} component="img" src={url} loading="lazy" ref={imageRef} />
      {showSkeleton && <Skeleton variant="rectangular" {...skeletonOptions} />}
    </Box>
  );
};

export const ImageDownloader = React.memo(ImageDownloaderMemorized);
