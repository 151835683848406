import { createApiWrapped } from '../api/api';
import * as I from '@models';

export const adsApi = createApiWrapped.injectEndpoints({
  endpoints: (build) => ({
    // here request's type should be 'GET'! but backend dev didn't do it
    getAds: build.query<I.AdsResponse, I.AdsFilters>({
      query: (params) => {
        const { page = 1, filterBy = 0, ...restParams } = params;
        return {
          method: 'POST',
          url: 'admin/real-estates/get-by-filter',
          body: { filter: { ...restParams, filterBy, offset: (page - 1) * 50, limit: 50 } },
        };
      },
      providesTags: ['Ads'],
    }),

    // get ads item
    getAdsItem: build.query<I.AdsItem, string | number>({
      query: (adsID) => ({
        url: `admin/real-estates/${adsID}`,
      }),
      providesTags: (_result, _error, adsID) => [{ type: 'AdsItem', id: adsID }],
    }),

    // add new ads
    addAdsItem: build.mutation<void, I.AdsItem>({
      query: (data) => ({
        method: 'POST',
        url: `admin/real-estates`,
        body: { ...data, id: null },
      }),
      invalidatesTags: (_result, error) => (error ? [] : ['Counters']),
    }),

    // edit ads item
    editAdsItem: build.mutation<void, I.AdsItem>({
      query: (body) => ({
        method: 'PUT',
        url: `admin/real-estates/${body.id}`,
        body,
      }),
      invalidatesTags: (_result, _error, body) => [{ type: 'AdsItem', id: body.id }],
    }),

    // delete ads item
    deleteAdsItem: build.mutation<number | string, unknown>({
      query(adsID) {
        return {
          method: 'DELETE',
          url: `admin/real-estates/${adsID}`,
        };
      },
      invalidatesTags: (_result, error) => (error ? [] : ['Ads', 'Counters']),
    }),

    deleteAutoItem: build.mutation<number | string, unknown>({
      query(adsID) {
        return {
          method: 'DELETE',
          url: `admin/auto/${adsID}`,
        };
      },
      invalidatesTags: (_result, error) => (error ? [] : ['Ads', 'Counters']),
    }),

    takeEstate: build.mutation<void, number | string>({
      query(adsID) {
        return {
          method: 'POST',
          url: 'admin/real-estates/take',
          body: { realEstateId: adsID },
        };
      },
      invalidatesTags: (_result, error) => (error ? [] : ['Ads', 'Counters']),
    }),

    refreshAds: build.mutation<void, number | string>({
      query(adsID) {
        return {
          method: 'PUT',
          url: `admin/real-estates/${adsID}/update-paid-date`,
        };
      },
      invalidatesTags: (_result, error) => (error ? [] : ['Ads', 'Counters']),
    }),
  }),
});
