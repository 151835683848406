import React, { useCallback, useMemo } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';

import { actionsPanel, photosCardSX, rootSX } from './PhotosConstructor.styles';
import { FormikProps } from 'formik';
import { Box, Grid, IconButton, Paper, Stack } from '@mui/material';
import { ImageCropper, WindowLoader, ImagePreview } from '../../../components';
import { SortableItem } from './SortableItem';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy } from '@dnd-kit/sortable';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import { IAutoListItem } from 'models/auto.models';
import { getCroppedImageUrl } from 'utils/helpers/getCroppedImageUrl';
import watermark from 'watermarkjs';
import { dataURLtoFile } from 'utils/dataURLToFile';
import { AdsItem, UploadPhotoResponse } from 'models';
import { commonApi } from '../../../store/common/common.api';
import { UseFormReturnType } from '@mantine/form';

const { useUploadPhotoMutation } = commonApi;

const getX = function (boat: any, logo: any) {
  return 15;
};

const getY = function (boat: any, logo: any) {
  return 15;
};

export function PhotosConstructor<T extends AdsItem | IAutoListItem>(props: {
  formik: FormikProps<T> | UseFormReturnType<T, (values: T) => T>;
}) {
  const [uploadPhoto, { isLoading }] = useUploadPhotoMutation();
  const { values, setFieldValue } = props.formik;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );

  // prettier-ignore
  const handleAddNewPhotos = useCallback(async (uploadedPhotos: ('' | File)[]) => {
    const responseList = await Promise.all(uploadedPhotos.map(async (photo) => {
      let currPhoto: any = null;
      await watermark([
       photo,
        '/main_logo.svg',
      ])
        .image(watermark.image.atPos(getX, getY, 0.5))
        .then((img: any) => {
          currPhoto = img.src
        })
        currPhoto = dataURLtoFile(currPhoto, (photo as File).name);
        return uploadPhoto(currPhoto);
    }));

    const photos: UploadPhotoResponse[] = [];
    responseList.forEach((res) => 'data' in res && photos.push(res.data));
    const settingPriority = [...values.photos, ...photos].map((photo, idx) => ({...photo, priority: idx }))

    setFieldValue('photos', settingPriority);
  }, [values.photos]);

  // prettier-ignore
  const handleDeletePhoto = useCallback((deletedIdx: number) => () => {
    setFieldValue('photos', values.photos.filter((_, idx) => deletedIdx !==idx))
  }, [values.photos])

  const photosParser = useMemo(
    () =>
      values.photos &&
      values.photos.map((photo, idx) => {
        const cardImgUrl = getCroppedImageUrl(photo.fileName);
        const ImgPreviewUrl = getCroppedImageUrl(photo.fileName);

        return (
          <SortableItem key={photo.id} id={photo.id}>
            <Box component={Paper} elevation={0} sx={photosCardSX}>
              <Box component="img" src={cardImgUrl} />
              <Stack sx={actionsPanel} justifyContent="flex-end">
                <ImagePreview imgURL={ImgPreviewUrl} />
                <IconButton onClick={handleDeletePhoto(idx)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </Box>
          </SortableItem>
        );
      }),
    [values.photos],
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (over && active.id !== over?.id) {
        const oldIndex = values.photos.findIndex((item) => item.id === active.id);
        const newIndex = values.photos.findIndex((item) => item.id === over.id);
        setFieldValue('photos', arrayMove(values.photos, oldIndex, newIndex));
      }
    },
    [values.photos],
  );

  // RENDER
  return (
    <>
      <Box mb={3} sx={rootSX}>
        <Grid container spacing={3}>
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={values.photos} strategy={rectSortingStrategy}>
              {photosParser}
            </SortableContext>
          </DndContext>

          {/* Add button */}
          <Grid item>
            <Box component={Paper} elevation={0} sx={photosCardSX}>
              <ImageCropper onSave={handleAddNewPhotos} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <WindowLoader open={isLoading} />
    </>
  );
}
