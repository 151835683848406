import * as I from '@models';
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';

const baseQuery = fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }) as BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError | I.ApiErrorResponse
>;

export const loginApi = createApi({
  reducerPath: 'app/loginApi',
  baseQuery,
  endpoints: (build) => ({
    signin: build.mutation<I.LoginResponse, I.Login>({
      query: (data) => ({
        url: 'user/login',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});
