import * as yup from 'yup';

// messages's list
const validationsMessages = {
  required: 'Поле обязательно для заполнения',
};

// Error message customization
yup.setLocale({
  mixed: {
    required: validationsMessages['required'],
    // eslint-disable-next-line
    notType: 'Значение поля должно быть "${type}"',
  },
});
