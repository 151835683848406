import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as I from '@models';

const initialState: I.HeaderSlice = {
  showPasswordDialog: false,
  showMobileDrawer: false,
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    passwordDialogSwitcher: (state, action: PayloadAction<boolean>) => {
      state.showPasswordDialog = action.payload;
    },
    mobileDrawerSwitcher: (state, action: PayloadAction<boolean>) => {
      state.showMobileDrawer = action.payload;
    },
  },
});

export const headerReducer = headerSlice.reducer;
export const headerActions = headerSlice.actions;
