import { useFormik, useFormikContext } from 'formik';
import type { FormikConfig, FormikValues } from 'formik';

type FormikErrors = {
  [index: string]: string | string[];
};

type FormikTouched = {
  [index: string]: boolean;
};

/*
  These hooks wrap the original hooks (useFormik & useFormikContext),
  and add additional functions
*/
export function useWrappedFormik<Values extends FormikValues = FormikValues>(params: FormikConfig<Values>) {
  const formik = useFormik(params);
  const { errors, touched } = formik;

  return {
    getErrorState: getErrorState(errors as FormikErrors, touched as FormikTouched),
    getErrorMessage: getErrorMessage(errors as FormikErrors, touched as FormikTouched),
    ...formik,
  };
}

export function useWrappedFormikContext<Values>() {
  const formik = useFormikContext<Values>();
  const { errors, touched } = formik;

  return {
    getErrorState: getErrorState(errors as FormikErrors, touched as FormikTouched),
    getErrorMessage: getErrorMessage(errors as FormikErrors, touched as FormikTouched),
    ...formik,
  };
}

const getErrorState = (errors: FormikErrors, touched: FormikTouched) => (name: string) => {
  try {
    return Boolean(errors[name] && touched[name]);
  } catch (e) {
    const error = e as Error;
    console.error(`Error in wrapped formik hook "getErrorState": ${error.message}`);
    return false;
  }
};

const getErrorMessage = (errors: FormikErrors, touched: FormikTouched) => (name: string) => {
  try {
    return errors[name] && touched[name] ? (errors[name] as string | string[]) : '';
  } catch (e) {
    const error = e as Error;
    console.error(`Error in wrapped formik hook "getErrorMessage": ${error.message}`);
    return '';
  }
};
