import React, { useCallback, useMemo, useState } from 'react';
import * as I from '@models';
import AspectIcon from '@mui/icons-material/AspectRatio';
import RotateIcon from '@mui/icons-material/Rotate90DegreesCcwSharp';
import ZoomIcon from '@mui/icons-material/ZoomInSharp';
import CloseIcon from '@mui/icons-material/HighlightOff';
import LeftArrowIcon from '@mui/icons-material/ChevronLeft';
import RightArrowIcon from '@mui/icons-material/ChevronRight';
import Cropper from 'react-easy-crop';
import Compressor from 'compressorjs';
import { getCroppedImg, getLocalPhoto, readFile } from '@utils';
import { Box, styled } from '@mui/system';
import { nanoid } from 'nanoid';
import { LoadingButton } from '@mui/lab';
import { Point, Area } from 'react-easy-crop/types';
import { Button, Dialog, DialogContent, IconButton, Paper, Slider, Stack } from '@mui/material';
import { contentSX, closeButtonSX, photosQuantitySX } from './ImageCropper.styles';
import Resizer from 'react-image-file-resizer';
import { getFileExt } from 'utils/helpers/getFileExt.helper';

const Input = styled('input')({
  display: 'none',
});

const aspectRatio = [1.91 / 1, 16 / 9, 1 / 1, 4 / 5, 9 / 16];

const cropperInitialData = {
  zoom: 1,
  rotation: 0,
  croppedAreaPixels: null,
  crop: { x: 0, y: 0 },
  cropSize: { width: 0, height: 0 },
  acceptIndex: 2,
};

export function ImageCropper(props: I.ImageCropperProps) {
  const { onSave, children, disabled, filesLimit, ...restCropperOtions } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [photos, setPhotos] = useState<I.ImageCropperPhotoData[]>([]);
  const [navigationCount, setNavigationCount] = useState(0);
  const currentPhoto = photos[navigationCount];
  const isShowNavigation = photos.length > 1;
  const isLoading = !photos.length && openDialog;
  const isAllPhotosCropperd = useMemo(() => !photos.find((photo) => photo.croppedAreaPixels === null), [photos]);
  const ID = useMemo(nanoid, []);

  // close
  const handleClose = () => {
    setPhotos([]);
    setNavigationCount(0);
    setOpenDialog(false);
  };

  // save
  const handleSave = async () => {
    try {
      const croppedLocalPhotos = await Promise.all(
        photos.map((photo) => getCroppedImg(photo.url, photo.croppedAreaPixels, photo.rotation)),
      );
      const files = await Promise.all(croppedLocalPhotos.map((photo) => getLocalPhoto(photo)));
      onSave(files);
      handleClose();
    } catch (e) {
      window.alert('При загрузке изображения произошла ошибка.');
    }
  };

  // upload file
  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const target = e.target;

      // validate files length
      if (!target.files || target.files.length < 0) return;

      const photosList: File[] = [];
      for (const file of target.files) {
        // validate file's type
        if (!file.type.includes('image')) {
          window.alert('Тип файла не является изображением');
          return;
        }
        // validate file's size
        if (file.size > 31457280) {
          window.alert('Размер вашего файла не должен превышать 30 МБ');
          return;
        }

        // limit
        if (filesLimit && photosList.length === filesLimit) {
          break;
        }

        photosList.push(file);
      }

      setOpenDialog(true);

      // compressing photos
      const compressedPhotos = await Promise.all<Blob>(
        photosList.map((photo) => {
          return new Promise((resolve) => {
            // new Compressor(photo, { quality: 0.6, success: resolve });
            Resizer.imageFileResizer(
              photo,
              600,
              600,
              getFileExt(photo.name),
              100,
              0,
              (uri) => {
                resolve(uri as Blob);
              },
              'blob',
            );
          });
        }),
      );

      // convert photos to local URL string
      const localizatedPhotos = await Promise.all(compressedPhotos.map((photo) => readFile(photo)));

      setPhotos(() =>
        localizatedPhotos.map((url) => ({
          url,
          ...cropperInitialData,
        })),
      );
    } catch (error) {
      window.alert('При загрузке изображения произошла ошибка.');
    }
  };

  // crop
  const handleCropChange = useCallback(
    (crop: Point) => {
      setPhotos((photos) =>
        photos.map((photo, idx) => {
          if (idx === navigationCount) {
            return { ...photo, crop };
          }
          return photo;
        }),
      );
    },
    [photos, navigationCount],
  );

  // aspect
  const handleAspectChange = useCallback(
    (e: unknown, acceptIndex: number | number[]) => {
      if (typeof acceptIndex !== 'number') return;
      setPhotos((photos) =>
        photos.map((photo, idx) => {
          if (idx === navigationCount) {
            return { ...photo, acceptIndex };
          }
          return photo;
        }),
      );
    },
    [photos, navigationCount],
  );

  // zoom
  const handleZoomChange = useCallback(
    (zoom: number) => {
      setPhotos((photos) =>
        photos.map((photo, idx) => {
          if (idx === navigationCount) {
            return { ...photo, zoom };
          }
          return photo;
        }),
      );
    },
    [photos, navigationCount],
  );

  // slider zoom
  const handleSliderZoomChange = useCallback(
    (_e: unknown, zoom: number | number[]) => {
      if (typeof zoom !== 'number') return;
      handleZoomChange(zoom);
    },
    [handleZoomChange],
  );

  // ratation
  const handleChangeRatation = useCallback(
    (_e: unknown, rotation: number | number[]) => {
      if (typeof rotation !== 'number') return;
      setPhotos((photos) =>
        photos.map((photo, idx) => {
          if (idx === navigationCount) {
            return { ...photo, rotation };
          }
          return photo;
        }),
      );
    },
    [photos, navigationCount],
  );

  // change area pixels
  const onCropComplete = useCallback(
    (_: unknown, croppedAreaPixels: Area) => {
      setPhotos((photos) =>
        photos.map((photo, idx) => {
          if (idx === navigationCount) {
            return { ...photo, croppedAreaPixels };
          }
          return photo;
        }),
      );
    },
    [photos, navigationCount],
  );

  // inrease navigation
  const handleIncreaseNavigation = useCallback(() => {
    setNavigationCount((count) => (count === photos.length - 1 ? 0 : count + 1));
  }, [photos]);

  // decrease navigation
  const handleDecreaseNavigation = useCallback(() => {
    setNavigationCount((count) => (count === 0 ? photos.length - 1 : count - 1));
  }, [photos]);

  const addButoon = (
    <Box component="label" htmlFor={ID}>
      {!disabled && <Input onChange={handleUploadFile} value="" accept="image/*" multiple id={ID} type="file" />}
      {children || (
        <Button component="span" color="secondary" sx={{ width: '100%', height: '100%' }}>
          Загружать
        </Button>
      )}
    </Box>
  );

  // RENDER
  if (!photos.length || !currentPhoto) {
    return addButoon;
  }

  const { acceptIndex, crop, rotation, url, zoom } = currentPhoto;

  return (
    <>
      {addButoon}

      <Dialog open={openDialog} maxWidth="sm" fullWidth>
        <DialogContent sx={contentSX}>
          <Cropper
            image={url}
            aspect={aspectRatio[acceptIndex]}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            zoomSpeed={0.5}
            onCropChange={handleCropChange}
            onZoomChange={handleZoomChange}
            onCropComplete={onCropComplete}
            {...restCropperOtions}
          />
          <IconButton onClick={handleClose} sx={closeButtonSX} color="inherit">
            <CloseIcon fontSize="large" />
          </IconButton>
        </DialogContent>

        <Box component={Stack} direction="column" spacing={2} px={4} py={2}>
          <Stack spacing={3} alignItems="center">
            <AspectIcon />
            <Slider min={0} max={4} marks value={acceptIndex} onChange={handleAspectChange} />
          </Stack>

          <Stack spacing={3} alignItems="center">
            <ZoomIcon />
            <Slider value={zoom} step={0.05} min={1} max={3} onChange={handleSliderZoomChange} />
          </Stack>

          <Stack spacing={3} alignItems="center">
            <RotateIcon />
            <Slider
              value={rotation}
              step={90}
              min={0}
              max={360}
              onChange={handleChangeRatation}
              valueLabelDisplay="auto"
              marks
            />
          </Stack>

          <Box component={Stack} justifyContent="space-between" spacing={1}>
            <Box component={Stack} spacing={1}>
              {isShowNavigation && (
                <Button onClick={handleDecreaseNavigation} color="inherit">
                  <LeftArrowIcon />
                </Button>
              )}

              {isShowNavigation && (
                <Button onClick={handleIncreaseNavigation} color="inherit">
                  <RightArrowIcon />
                </Button>
              )}
            </Box>

            {!isAllPhotosCropperd && (
              <Box component={Paper} sx={photosQuantitySX} elevation={0}>
                {navigationCount + 1} / {photos.length}
              </Box>
            )}

            {isAllPhotosCropperd && (
              <LoadingButton onClick={handleSave} loading={isLoading} variant="contained">
                Загрузить
              </LoadingButton>
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
