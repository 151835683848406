import { drawerWidth } from '@utils';

export const rootSX = {
  width: { sm: drawerWidth },
  flexShrink: { sm: 0 },
};

export const desctopDrawerSX = {
  display: { xs: 'none', sm: 'block' },
  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
};

export const mobileDrawerSX = {
  display: { xs: 'block', sm: 'none' },
  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
};

export const drawerHeaderSX = {
  position: 'relative',
};

export const logoSX = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 40,
};

export const innerItemSX = {
  pl: 4,
};
